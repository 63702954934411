package eu.drewnomaniak.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import eu.drewnomaniak.models.Section
import eu.drewnomaniak.utils.Constants
import eu.drewnomaniak.utils.Res
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun MainSection(onMenuClicked: () -> Unit) {
    Box(
        modifier = Modifier
            .fillMaxSize()
            .id(Section.Home.id),
        contentAlignment = Alignment.Center
    ) {
        MainBackground()
        MainContent(onMenuClicked = onMenuClicked)
    }
}

@Composable
private fun MainBackground() {
    val backgroundImageUrl = url(Res.Image.background)

    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(Background.of(BackgroundImage.of(backgroundImageUrl)))
            .backgroundRepeat(BackgroundRepeat.Repeat)
    )
}

val RotateKeyframes = Keyframes {
    from { Modifier.rotateX(0.deg) }
    to { Modifier.rotateX(360.deg) }
}

@Composable
private fun MainContent(onMenuClicked: () -> Unit) {
    Column(
        modifier = Modifier
            .margin(left = 30.px),
        verticalArrangement = Arrangement.SpaceAround,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Image(
            src = Res.Icon.maintenance,
            modifier = Modifier
                .size(150.px)

                .animation(RotateKeyframes.toAnimation(
                    duration = 5.s,
                    iterationCount = AnimationIterationCount.Infinite
                )),
        )
        P(
            attrs = Modifier
                .fillMaxWidth()
                .textAlign(TextAlign.Center)
                .margin(
                    left = 10.px,
                    right = 10.px,
                    bottom = 10.px,
                    top = 0.px
                )
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(45.px)
                .fontWeight(FontWeight.Bold)
                .color(Colors.White)
                .toAttrs()
        ) {
            Text("Drewnomaniak")
        }
        P(
            attrs = Modifier
                .fillMaxWidth()
                .textAlign(TextAlign.Center)
                .margin(
                    left = 10.px,
                    right = 10.px,
                    bottom = 10.px,
                    top = 0.px
                )
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(15.px)
                .fontWeight(FontWeight.Normal)
                .color(Colors.Gray)
                .toAttrs()
        ) {
            Text("Aktualnie trwają prace nad tą witryną. Zajrzyj tu za jakiś czas.")
        }
    }
}