package eu.drewnomaniak.utils

object Constants {
    const val WEBSITE = "http://drewnomaniak.eu"
    const val FONT_FAMILY = "Roboto"

    const val LOREM_IPSUM_LONG =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    const val LOREM_IPSUM_SHORTEST =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    const val LOREM_IPSUM_SHORT =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."

}

object Res {
    object Icon {
        const val web = "web_icon.svg"
        const val seo = "seo_icon.svg"
        const val design = "design_icon.svg"
        const val business = "business_icon.svg"
        const val apple = "apple_icon.svg"
        const val android = "android_icon.svg"
        const val diagram = "diagram_icon.svg"
        const val technologies = "technologies_icon.svg"
        const val money = "good_price.svg"
        const val brain = "brain_icon.svg"
        const val link = "link_icon.svg"
        const val user = "user_icon.svg"
        const val shield = "shield_icon.svg"
        const val happy = "happy_icon.svg"
        const val checkmark = "checkmark_icon.svg"
        const val star = "star_icon.svg"
        const val maintenance = "ic_maintenance.svg"
    }

    object Image {
        const val background = "background.jpeg"
        const val logo = "logo_white.svg"
        const val futuremind_logo_blue = "futuremind_logo_blue.svg"
        const val futuremind_logo_dark_blue = "futuremind_logo_dark_blue.svg"
        const val logo_base = "logo_white_simple.svg"
        const val logo_extension = "logo_white_company_ceo.svg"
        const val potional_logo = "potional_logo.png"
        const val gigaset_logo = "gigaset_logo.jpeg"
        const val futuremind_logo = "futuremind_logo.svg"
        const val main = "codevibe_profile.png"
        const val about = "about_image.png"
        const val shoply_1 = "shoply_1.png"
        const val shoply_2 = "shoply_2.png"
        const val shoply_3 = "shoply_3.png"
        const val shoply_4 = "shoply_4.png"
        const val shoply_5 = "shoply_5.png"
        const val shoply_app_logo = "shoply_logo.png"
        const val zappka_1 = "zappka_1.png"
        const val zappka_2 = "zappka_2.png"
        const val zappka_3 = "zappka_3.png"
        const val zappka_4 = "zappka_4.png"
        const val zappka_5 = "zappka_5.png"
        const val zappka_6 = "zappka_6.png"
        const val zappka_7 = "zappka_7.png"
        const val zappka_8 = "zappka_8.png"
        const val zappka_app_logo = "zappka_logo.png"
        const val gigaset_1 = "gigaset_elements_1.png"
        const val gigaset_2 = "gigaset_elements_2.png"
        const val gigaset_3 = "gigaset_elements_3.png"
        const val gigaset_4 = "gigaset_elements_4.png"
        const val gigaset_5 = "gigaset_elements_5.png"
        const val gigaset_6 = "gigaset_elements_6.png"
        const val gigaset_7 = "gigaset_elements_7.png"
        const val gigaset_8 = "gigaset_elements_8.png"
        const val gigaset_app_logo = "gigaset_elements_logo.png"
        const val avatar1 = "avatar1.png"
        const val avatar2 = "avatar2.png"
        const val avatar3 = "avatar3.png"
        const val avatar4 = "avatar4.png"
        const val avatar5 = "avatar5.png"
        const val avatar6 = "avatar6.png"
    }

    object Text {
        const val codevibe_short_description = "I am a native Android developer with experience in several large commercial projects. I have been programming professionally since 2018. I am constantly developing myself and I will be happy to develop your application project as well!"
    }
}